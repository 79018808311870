import React from 'react'
import Inview from 'components/Inview'
import MarkdownWrap from 'components/MarkdownWrap'
import ButtonLink from 'components/ButtonLink'
import * as styles from './styles.module.scss'

const Block = ({ content }) => {
  const { body, buttonLink } = content
  return (
    <div className={`block pad-v-standard`}>
      <Inview className={`${styles.container} container fade-in up`}>
        <MarkdownWrap body={body} />
        {buttonLink && (
          <div className='button-wrap'>
            <ButtonLink content={buttonLink} />
          </div>
        )}
      </Inview>
    </div>
  )
}

export default Block
