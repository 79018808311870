import React from 'react'
import LinkWrap from 'components/LinkWrap'
import Inview from 'components/Inview'
import * as styles from './styles.module.scss'

const Block = ({ content, padBottomLess }) => {
  const { pretitle, pretitleLink, title, description } = content
  const padBottomLessClass = padBottomLess ? 'pad-bottom-less' : ''
  return (
    <div
      className={`block pad-v-standard first-add-v-pad ${padBottomLessClass}`}
    >
      <Inview className='container fade-in up'>
        <div className={styles.text}>
          {pretitle && pretitleLink && (
            <h5>
              <LinkWrap to={pretitleLink}>{pretitle}</LinkWrap>
            </h5>
          )}
          {pretitle && !pretitleLink && <h5>{pretitle}</h5>}
          <h1>{title}</h1>
          {description && <p>{description}</p>}
        </div>
      </Inview>
    </div>
  )
}

export default Block
