import React from 'react'
// import { navigate } from 'gatsby'
import BannerTextOnly from 'blocks/BannerTextOnly/template'
import TextOnly from 'blocks/TextOnly/template'
import Seo from 'components/Seo'
import { LocaleContext } from 'gatsby-theme-i18n'

const Page = () => {
  const locale = React.useContext(LocaleContext)

  // if not already logged in, go to login
  // if (typeof sessionStorage !== 'undefined') {
  //   if (!sessionStorage.getItem('user')) {
  //     const localePrefix = locale === 'en' ? '/en' : ''
  //     navigate(`${localePrefix}/account`)
  //   }
  // }

  let setPath = '/'
  if (typeof sessionStorage !== 'undefined') {
    const lastPath = sessionStorage.getItem('lastPath')
    const setHomeTo = locale === 'en' ? '/en' : '/'
    setPath = lastPath ? lastPath : setHomeTo
  }

  const strings = {
    en: {
      accounts: 'Accounts',
      welcome: 'Welcome',
      welcomeMessage: 'Welcome to the site. You have been logged in.',
      ok: 'Ok',
    },
    zh: {
      accounts: '账户',
      welcome: '欢迎',
      welcomeMessage: `欢迎来到拙鱼官方网站， 您已登录！`,
      ok: 'Ok',
    },
  }
  const tr = strings[locale]

  const seo = {
    title: `${tr.accounts} - ${tr.welcome}`,
  }
  const blockContent = {
    bannerTextOnly: {
      pretitle: tr.accounts,
      pretitleLink: '/account',
      title: tr.welcome,
    },
    textOnly: {
      body: tr.welcomeMessage,
      buttonLink: {
        text: tr.ok,
        to: setPath,
        bypassLocalization: true,
        linkType: 'page',
      },
    },
  }

  return (
    <>
      <Seo content={seo} />
      <BannerTextOnly
        content={blockContent.bannerTextOnly}
        padBottomLess={true}
      />
      <TextOnly content={blockContent.textOnly} />
    </>
  )
}

export default Page
